<template>
    <div class="con-wrap">
        <CarrotTitle title="팀 교육 관리">
            - 교육 현황을 볼 수 있습니다.
        </CarrotTitle>
        <div v-if="bbs.isAuth==true" class="board">
            <div class="mt-40">
                <table class="table-row table-serach">
                    <colgroup>
                        <col style="width:160px">
                        <col style="width:440px">
                        <col style="width:160px">
                        <col style="width:440px">
                    </colgroup>
                    <tbody>
                        <tr>
                            <th>본부/팀</th>
                            <td>
                                <carrot-dept v-model="bbs.office" class="w-200px mr-10"></carrot-dept>
                                <carrot-team v-model="bbs.team" v-model:idx_office.sync="bbs.office" class="w-200px"></carrot-team>
                            </td>
                            <th>이름</th>
                            <td>
                                <input type="text" class="w-100per" v-model="bbs.stext">
                            </td>
                        </tr>
                    </tbody>
                </table>
                <button class="btn-default float-right mt-10" @click="bbs.doInit()">검색</button>
                <div class="clear"></div>
            </div>
            <div class="mt-40 mb-20">
                <div>
                    <table class="table-col">
                        <colgroup>
                            <col span="4">
                        </colgroup>
                        <thead>
                            <tr>
                                <th>평균<br>필요 교육 시간</th>
                                <th>평균<br>이수 교육 수</th>
                                <th>평균<br>교육 이수 시간</th>
                                <th>평균<br>교육 이수율</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{{ bbs.info.required_time }}시간</td>
                                <td>{{ bbs.info.complete_avg  }}</td>
                                <td>{{ bbs.info.complete_time }}시간</td>
                                <td>{{ bbs.info.complete_rate }}%</td>
                            </tr>
                        </tbody>
                    </table>

                    <div class="mt-50 mb-20">
                        <select name="syear" id="syear" class="w-100px float-left" v-model="bbs.syear" @change="bbs.doInit()">
                            <option :value="v" v-for="(v, k) in bbs.search_years" :key="k">{{ v }}</option>
                        </select>
                        <select name="state" id="state" class="w-100px float-left ml-10" v-model="bbs.state" @change="bbs.doInit()">
                            <option value="">전체</option>
                            <option value="DUTY">재직자</option>
                            <option vlaue="QUIT">퇴직자</option>
                        </select>
                        <carrot-position v-model="bbs.position" @change="bbs.doInit()" class="float-left ml-10"></carrot-position>

                        <button class="btn-default float-right ml-10" @click="bbs.downExcel()">엑셀 다운로드</button>
                        <div class="clear"></div>
                    </div>

                    <table class="table-col">
                        <colgroup>
                            <col width="60">
                            <col width="60">
                            <col width="*">
                            <col width="80">
                            <col width="160">
                            <col width="160">
                            <col width="*">
                            <col width="80">
                            <col width="80">
                            <col width="80">
                            <col width="90">
                        </colgroup>
                        <thead>
                            <tr>
                                <th>No</th>
                                <th>구분</th>
                                <th>직급</th>
                                <th>소속법인</th>
                                <th>본부</th>
                                <th>팀</th>
                                <th>이름</th>
                                <th>필요<br>교육 시간</th>
                                <th>이수<br>교육 수</th>
                                <th>교육<br>이수 시간</th>
                                <th>교육<br>시간 이수율</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(irow, i) in bbs.list" :key="i">
                                <td>{{ irow.num }}</td>
                                <td>{{ irow.inoffice=="DUTY"?"재직자":"퇴직자" }}</td>
                                <td>{{ irow.position_name }}</td>
                                <td>{{ irow.coporation_name }}</td>
                                <td>{{ irow.office_name }}</td>
                                <td class="txt-ellipsis">{{ irow.team_name }}</td>
                                <td><router-link :to="{ name:'myPIMS-PersonalInfoList_tab5-idx', params:{idx:irow.idx_hq} }" target="_blank"><span class="btn-view">{{ irow.ename + '(' + irow.kname + ')' }}</span></router-link></td>
                                <td>{{ irow.required_time }}</td>
                                <td>{{ irow.complete_cnt }}</td>
                                <td>{{ irow.complete_time }}</td>
                                <td>{{ irow.complete_rate }}%</td>
                            </tr>
                            <tr v-if="bbs.total==0">
                                <td colspan="11">이수한 교육이 없습니다.</td>
                            </tr>
                        </tbody>
                    </table>
                    <CarrotPaging :total="bbs.total" :list_per_page="bbs.rows" v-model="bbs.page" @change="bbs.doSearch()"></CarrotPaging>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import { onMounted, reactive } from 'vue'
import { useRouter } from 'vue-router'
import { useToast } from 'vue-toastification';
import { useStore } from 'vuex'
import axios from '@/plugins/axios.js'
import Swal from 'sweetalert2'
import CarrotDept from '@/components/common/CarrotDept.vue'
import CarrotTeam from '@/components/common/CarrotTeam.vue'
import CarrotPosition from '@/components/common/CarrotPosition.vue'


export default {
    layout:"myPIMS",
    components: {
        CarrotDept,
        CarrotTeam,
        CarrotPosition
    },
    setup() {
        const router = useRouter()
        const toast = useToast()
        const store = useStore();
        const bbs = reactive({
            search_years : [ ],
            isMounted : false,
            isAuth    : false,

            page : 1,
            rows : 20,
            office  : "0",
            team    : "0",
            syear   : "",
            skind   : "wnm",
            stext   : "",
            state   : "DUTY",
            position: "",

            info : {
                required_time : 120,
                complete_avg  : 9,
                complete_time : 26.5,
                complete_rate : 60
            },
            list : [], total : 0,

            downExcel : () => {
                let url = "https://api.carrotians.net/excel/education_dashboard?office=" + bbs.office + "&team=" + bbs.team + "&skind=" + bbs.skind + "&stext=" + bbs.stext + "&syear=" + bbs.syear + "&state=" + bbs.state + "&position=" + bbs.position;
                window.open(url, "_blank").focus();
            },

            getInfo: () => {
                let params = { 
                    office   : bbs.office,
                    team     : bbs.team,
                    skind    : bbs.skind,
                    stext    : bbs.stext,

                    tgdate   : bbs.syear,
                    state    : bbs.state,
                    position : bbs.position,
                };
                axios.get('/rest/mypims/getEducationHQInfo', { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        bbs.info.required_time = res.data.required_time;
                        bbs.info.complete_avg  = res.data.complete_avg;
                        bbs.info.complete_time = res.data.complete_time;
                        bbs.info.complete_rate = res.data.complete_rate;

                        bbs.isAuth = res.data.is_auth=='Y'?true:false;

                        if( bbs.isAuth == false && store.state.pd_auth == false ) {
                        //if( bbs.isAuth == false ){
                            Swal.fire({
                                title : '팀 교육 관리',
                                text  : '권한이 없습니다.'
                            }).then(() => {
                                router.go(-1);
                            });
                        } else {
                            bbs.doSearch();
                        }
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },

            doInit : () => {
                bbs.isMounted = true;
                bbs.page = 1;
                bbs.getInfo();
            },
            doSearch : () => {
                if( bbs.isMounted == false ) return;
                let params = {
                    page : bbs.page,
                    rows : bbs.rows,

                    office   : bbs.office,
                    team     : bbs.team,
                    skind    : bbs.skind,
                    stext    : bbs.stext,

                    tgdate   : bbs.syear,
                    state    : bbs.state,
                    position : bbs.position,
                };
                axios.get('/rest/mypims/getEducationHQList', { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        bbs.list  = res.data.list;
                        bbs.total = res.data.total;
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                })
            },
            showView: (idx)=>{
                router.push({
                    name   : 'myPIMS-EduManagementView-idx',
                    params : { idx:idx }
                });
            },
            showAdd: () => {
                router.push('EducationAdd');
            },
            makeSearchYears: () => {
                let cd = new Date();
                let cy = cd.getFullYear();
                for(let i=cy; i>=2003; i--){
                    bbs.search_years.push(i);
                }
            }
        });

        
        onMounted(() => {

           
            //초기 로딩시 본인의 부서/팀 선택될 수 있도록 처리.
            bbs.office = store.state.idx_office;
            bbs.team = store.state.idx_team;
            
            let cd = new Date();
            bbs.syear = cd.getFullYear();
            bbs.makeSearchYears();

            bbs.doInit();
        });

        return {bbs};
    }
}
</script>

<style lang="scss" scoped>
</style>